<template>
    <div class="carousel"  @keydown="checkSlide($event)" tabindex="0">
        <slot></slot>
        <button @click.prevent="next" class="btn btn-next"><i class="fa fa-angle-right"></i></button>
        <button @click.prevent="prev" class="btn btn-prev"><i class="fa fa-angle-left"></i></button>
    </div>
</template>
<script>
export default {
    data () {
        return {
            index : 0,
            slides : [],
            slideDirection: '',
            timer: null
        }
    },
    computed: {
        slidesLength() {
            return this.slides.length;
        }
    },
    mounted(){
        this.slides = this.$children;
        this.slides.map( (slide,index) => {
            slide.index = index;
        });
        this.timer = setInterval(() => {
            //this.countDown()
            this.next();
        }, 5000);
    },
    methods: {
        next(){
            this.index++;
            if(this.index >= this.slidesLength){
                this.index = 0;
            }
            this.slideDirection = 'slide-right';
            clearInterval(this.timer);
            this.timer = setInterval(() => {
                //this.countDown()
                this.next();
            }, 5000);
        },
        prev(){
            this.index--;
            if(this.index < 0){
                this.index = this.slidesLength - 1;
            }
             this.slideDirection = 'slide-left';
             clearInterval(this.timer);
            this.timer = setInterval(() => {
                //this.countDown()
                this.next();
            }, 5000);
        },
        checkSlide(event){
            if(event.keyCode === 39){
                this.next();
            }else if (event.keyCode === 37){
                this.prev();
            }else {
                return;
            }
        },
    },

    beforeDestroy() {
        clearInterval(this.timer)
    }
}
</script>
<style scoped>
      .btn {
        padding:5px 5px;
        /*background-color:rgba(0,0,0,0.2);*/
        border:1px solid transparent;
        margin:5px 10px;
        color:#FFF;
        height:50px;
        width:50px;
        position:absolute;
        margin-top:-25px;
        /*z-index:1;*/
    }
    .btn i {
        font-size: 24px;
    }
    .btn:hover {
        cursor: pointer;
        color: #e0dfdfdb;
    }
    .btn:focus{
        outline:none;
    }
    .btn-next {
        top:50%;
        right:0;
    }
    .btn-prev {
        top:50%;
        left:0;
    }  
</style>